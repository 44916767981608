"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateQuery = exports.filter = exports.executeQuery = exports.isOperator = void 0;

var _aggregator = require("mingo/aggregator");

require("mingo/init/system");

var _mingo = _interopRequireDefault(require("mingo"));

// We can support tree shaking and cherry pick operators, but all the operators in Mingo are useful,
// depending on your use case. Moreover, the largeness of Mingo's bundle size is mostly due to its
// operators, therefore it isn't possible to exclude other core mingo functionality. For now, we'll
// choose not to make any such cherry-picking optimizations.
// import "mingo/init/basic";
// import { useOperators, OperatorType } from "mingo/core";
// import { $cond } from "mingo/operators/expression";
// useOperators(OperatorType.EXPRESSION, { $cond });
var isOperator = function isOperator(key) {
  // If the key starts with $ then assume it is an operator. This is the same method that mingo
  // uses:
  // https://github.com/kofrasa/mingo/blob/924e8f5d1411a5879983de6c986dfdaf12bcb459/src/util.ts#L890
  return typeof key === 'string' && key[0] === '$';
};

exports.isOperator = isOperator;

var executeQuery = function executeQuery(obj) {
  var agg = new _aggregator.Aggregator([{
    $project: {
      value: obj
    }
  }]); // We use an empty collection as substitution of parameters is handled by MSON's template
  // parameters, which are swapped out before the mingo query is run

  var collection = [{}];
  var result = agg.run(collection);
  return result && result[0] && result[0].value;
};

exports.executeQuery = executeQuery;

var filter = function filter(collection, query) {
  var q = new _mingo.default.Query(query);
  var cursor = q.find(collection);
  return cursor.all();
};

exports.filter = filter;

var validateQuery = function validateQuery(query) {
  var q = new _mingo.default.Query(query);
  q.test([]);
};

exports.validateQuery = validateQuery;