import { Children, cloneElement, isValidElement } from 'react';

var renderAndAddProps = function (renderable, props) {
    if (typeof renderable === "function") {
        return renderable(props);
    }
    else if (Children.count(renderable) > 0) {
        return Children.map(renderable, function (child) {
            return cloneElement(child, props);
        });
    }
    else if (isValidElement(renderable)) {
        return cloneElement(renderable, props);
    }
    return null;
};

export default renderAndAddProps;
export { renderAndAddProps };
