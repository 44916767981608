"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var recoverPassword = {
  name: 'RecoverPassword',
  component: 'Form',
  schema: {
    component: 'Form',
    fields: [{
      name: 'action',
      component: 'Field',
      label: 'Action'
    }]
  },
  fields: [{
    name: 'email',
    component: 'EmailField',
    label: 'Email',
    required: true
  }],
  listeners: [{
    event: 'createRecord',
    actions: [{
      component: 'RequestPasswordReset'
    }, '{{action}}']
  }]
};
var _default = recoverPassword;
exports.default = _default;