"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.throwIfNotPropertyNotDefinedError = exports.queryToPropNames = void 0;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _forEach = _interopRequireDefault(require("lodash/forEach"));

var _set = _interopRequireDefault(require("lodash/set"));

var _propertyNotDefinedError = _interopRequireDefault(require("./property-not-defined-error"));

var queryToPropNames = function queryToPropNames(query, names, parentName) {
  if (query !== null && (0, _typeof2.default)(query) === 'object') {
    if (Array.isArray(query)) {
      query.forEach(function (item) {
        return queryToPropNames(item, names, parentName);
      });
    } else {
      (0, _forEach.default)(query, function (item, name) {
        var itemParentName = parentName; // Not operator? TODO: actually check operators so that we support variables that begin with
        // $

        if (name.charAt(0) !== '$') {
          itemParentName = parentName === undefined ? name : parentName + '.' + name;
        }

        if (name === '$elemMatch') {
          // We want to set the parent item and then skip the rest of the current tree so that the
          // query can scan the entire array. We cannot determine which array element will be chosen
          // until the query is run.
          names[itemParentName] = true;
        } else {
          queryToPropNames(item, names, itemParentName);
        }
      });
    }
  } else {
    names[parentName] = true;
  }
};

exports.queryToPropNames = queryToPropNames;

var throwIfNotPropertyNotDefinedError = function throwIfNotPropertyNotDefinedError(err) {
  if (!(err instanceof _propertyNotDefinedError.default)) {
    throw err;
  }
}; // We analyze the MongoDB-style query to dynamically extract the properties from the component,
// including properties in deeply nested components. A previous design called get() on the
// components to return the values, but that does not always provide access to deeply nested
// components.
//
// TODO: is there a more efficient way of extracting these values via a callback in the query?


exports.throwIfNotPropertyNotDefinedError = throwIfNotPropertyNotDefinedError;

var queryToProps = function queryToProps(query, component) {
  var props = {};
  var names = {};
  queryToPropNames(query, names);
  (0, _forEach.default)(names, function (value, name) {
    try {
      (0, _set.default)(props, name, component.get(name));
    } catch (err) {
      // Swallow the error if the property is not defined. This allows us to do things like ignore
      // outdated filters, e.g. a filter for a property, which has since been deleted.
      throwIfNotPropertyNotDefinedError(err);
    }
  });
  return props;
};

var _default = queryToProps;
exports.default = _default;