"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Aggregator = void 0;
var core_1 = require("./core");
var lazy_1 = require("./lazy");
var util_1 = require("./util");
/**
 * Provides functionality for the mongoDB aggregation pipeline
 *
 * @param pipeline an Array of pipeline operators
 * @param options An optional Options to pass the aggregator
 * @constructor
 */
var Aggregator = /** @class */ (function () {
    function Aggregator(pipeline, options) {
        this.__pipeline = pipeline;
        this.__options = core_1.makeOptions(options);
    }
    /**
     * Returns an `Lazy` iterator for processing results of pipeline
     *
     * @param {*} collection An array or iterator object
     * @param {Query} query the `Query` object to use as context
     * @returns {Iterator} an iterator object
     */
    Aggregator.prototype.stream = function (collection) {
        var iterator = lazy_1.Lazy(collection);
        if (!util_1.isEmpty(this.__pipeline)) {
            // run aggregation pipeline
            for (var _i = 0, _a = this.__pipeline; _i < _a.length; _i++) {
                var operator = _a[_i];
                var operatorKeys = Object.keys(operator);
                var op = operatorKeys[0];
                var call = core_1.getOperator(core_1.OperatorType.PIPELINE, op);
                util_1.assert(operatorKeys.length === 1 && !!call, "invalid aggregation operator " + op);
                iterator = call(iterator, operator[op], this.__options);
            }
        }
        return iterator;
    };
    /**
     * Return the results of the aggregation as an array.
     *
     * @param {*} collection
     * @param {*} query
     */
    Aggregator.prototype.run = function (collection) {
        return this.stream(collection).value();
    };
    return Aggregator;
}());
exports.Aggregator = Aggregator;
