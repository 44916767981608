"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var provinceField = {
  component: 'TextFieldHiddenSchema',
  maxLength: 60
};
var _default = provinceField;
exports.default = _default;