"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var optionsField = {
  component: 'CollectionField',
  label: 'Options',
  forbidOrder: false,
  formFactory: {
    component: 'Factory',
    product: {
      component: 'Form',
      fields: [{
        name: 'label',
        label: 'Label',
        component: 'TextField',
        required: true,
        block: false
      }, {
        name: 'value',
        label: 'Value',
        component: 'TextField',
        required: true
      }]
    }
  }
};
var _default = optionsField;
exports.default = _default;