"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.$group = void 0;
var core_1 = require("../../core");
var util_1 = require("../../util");
/**
 * Groups documents together for the purpose of calculating aggregate values based on a collection of documents.
 *
 * @param collection
 * @param expr
 * @param options
 * @returns {Array}
 */
function $group(collection, expr, options) {
    // lookup key for grouping
    var ID_KEY = "_id";
    var id = expr[ID_KEY];
    return collection.transform(function (coll) {
        var partitions = util_1.groupBy(coll, function (obj) { return core_1.computeValue(obj, id, null, options); }, options === null || options === void 0 ? void 0 : options.hashFunction);
        // remove the group key
        expr = util_1.into({}, expr);
        delete expr[ID_KEY];
        var i = -1;
        var size = partitions.keys.length;
        return function () {
            if (++i === size)
                return { done: true };
            var value = partitions.keys[i];
            var obj = {};
            // exclude undefined key value
            if (value !== undefined) {
                obj[ID_KEY] = value;
            }
            // compute remaining keys in expression
            for (var _i = 0, _a = Object.entries(expr); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], val = _b[1];
                obj[key] = core_1.computeValue(partitions.groups[i], val, key, options);
            }
            return { value: obj, done: false };
        };
    });
}
exports.$group = $group;
