"use strict";
// $slice operator. https://docs.mongodb.com/manual/reference/operator/projection/slice/#proj._S_slice
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.$slice = void 0;
var util_1 = require("../../util");
var slice_1 = require("../expression/array/slice");
/**
 * Limits the number of elements projected from an array. Supports skip and limit slices.
 *
 * @param obj
 * @param field
 * @param expr
 */
function $slice(obj, expr, field, options) {
    var xs = util_1.resolve(obj, field);
    var exprAsArray = expr;
    if (!util_1.isArray(xs))
        return xs;
    return slice_1.$slice(obj, expr instanceof Array ? __spreadArrays([xs], exprAsArray) : [xs, expr], options);
}
exports.$slice = $slice;
