"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.redact = exports.computeValue = exports.addOperators = exports.getOperator = exports.useOperators = exports.OperatorType = exports.makeOptions = void 0;
var util_1 = require("./util");
/**
 * Creates an Option from another required keys are initialized
 * @param options Options
 */
function makeOptions(options) {
    return Object.assign({ idKey: "_id" }, options || {});
}
exports.makeOptions = makeOptions;
/**
 * The different groups of operators
 */
var OperatorType;
(function (OperatorType) {
    OperatorType["ACCUMULATOR"] = "accumulator";
    OperatorType["EXPRESSION"] = "expression";
    OperatorType["PIPELINE"] = "pipeline";
    OperatorType["PROJECTION"] = "projection";
    OperatorType["QUERY"] = "query";
})(OperatorType = exports.OperatorType || (exports.OperatorType = {}));
// operator definitions
var OPERATORS = (_a = {},
    _a[OperatorType.ACCUMULATOR] = {},
    _a[OperatorType.EXPRESSION] = {},
    _a[OperatorType.PIPELINE] = {},
    _a[OperatorType.PROJECTION] = {},
    _a[OperatorType.QUERY] = {},
    _a);
/**
 * Validates the object collection of operators
 */
function validateOperators(operators) {
    for (var _i = 0, _a = Object.entries(operators); _i < _a.length; _i++) {
        var _b = _a[_i], k = _b[0], v = _b[1];
        util_1.assert(v instanceof Function && util_1.isOperator(k), "invalid operator specified");
    }
}
/**
 * Register fully specified operators for the given operator class.
 *
 * @param cls Category of the operator
 * @param operators Name of operator
 */
function useOperators(cls, operators) {
    validateOperators(operators);
    util_1.into(OPERATORS[cls], operators);
}
exports.useOperators = useOperators;
/**
 * Returns the operator function or null if it is not found
 * @param cls Category of the operator
 * @param operator Name of the operator
 */
function getOperator(cls, operator) {
    return util_1.has(OPERATORS[cls], operator) ? OPERATORS[cls][operator] : null;
}
exports.getOperator = getOperator;
/**
 * Add new operators
 *
 * @param cls the operator class to extend
 * @param operatorFn a callback that accepts internal object state and returns an object of new operators.
 */
function addOperators(cls, operatorFn) {
    var newOperators = operatorFn({ computeValue: computeValue, resolve: util_1.resolve });
    validateOperators(newOperators);
    // check for existing operators
    for (var _i = 0, _a = Object.entries(newOperators); _i < _a.length; _i++) {
        var _b = _a[_i], op = _b[0], _ = _b[1];
        var call = getOperator(cls, op);
        util_1.assert(!call, op + " already exists for '" + cls + "' operators");
    }
    var wrapped = {};
    switch (cls) {
        case OperatorType.QUERY:
            var _loop_1 = function (op, f) {
                var fn = f.bind(newOperators);
                wrapped[op] = function (selector, value, options) { return function (obj) {
                    // value of field must be fully resolved.
                    var lhs = util_1.resolve(obj, selector, { unwrapArray: true });
                    return fn(selector, lhs, value, options);
                }; };
            };
            for (var _c = 0, _d = Object.entries(newOperators); _c < _d.length; _c++) {
                var _e = _d[_c], op = _e[0], f = _e[1];
                _loop_1(op, f);
            }
            break;
        case OperatorType.PROJECTION:
            var _loop_2 = function (op, f) {
                var fn = f.bind(newOperators);
                wrapped[op] = function (obj, expr, selector, options) {
                    var lhs = util_1.resolve(obj, selector);
                    return fn(selector, lhs, expr, options);
                };
            };
            for (var _f = 0, _g = Object.entries(newOperators); _f < _g.length; _f++) {
                var _h = _g[_f], op = _h[0], f = _h[1];
                _loop_2(op, f);
            }
            break;
        default:
            var _loop_3 = function (op, fn) {
                wrapped[op] = function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    return fn.apply(newOperators, args);
                };
            };
            for (var _j = 0, _k = Object.entries(newOperators); _j < _k.length; _j++) {
                var _l = _k[_j], op = _l[0], fn = _l[1];
                _loop_3(op, fn);
            }
    }
    // toss the operator salad :)
    useOperators(cls, wrapped);
}
exports.addOperators = addOperators;
/* eslint-disable unused-imports/no-unused-vars-ts */
/**
 * Implementation of system variables
 * @type {Object}
 */
var systemVariables = {
    $$ROOT: function (obj, expr, options) {
        return options.root;
    },
    $$CURRENT: function (obj, expr, options) {
        return obj;
    },
    $$REMOVE: function (obj, expr, options) {
        return undefined;
    },
};
/**
 * Implementation of $redact variables
 *
 * Each function accepts 3 arguments (obj, expr, opt)
 *
 * @type {Object}
 */
var redactVariables = {
    $$KEEP: function (obj, expr, options) {
        return obj;
    },
    $$PRUNE: function (obj, expr, options) {
        return undefined;
    },
    $$DESCEND: function (obj, expr, options) {
        // traverse nested documents iff there is a $cond
        if (!util_1.has(expr, "$cond"))
            return obj;
        var result;
        var newObj = util_1.cloneDeep(obj);
        for (var _i = 0, _a = Object.entries(newObj); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], current = _b[1];
            if (util_1.isObjectLike(current)) {
                if (current instanceof Array) {
                    var array = [];
                    for (var _c = 0, current_1 = current; _c < current_1.length; _c++) {
                        var elem = current_1[_c];
                        if (util_1.isObject(elem)) {
                            elem = redact(elem, expr, options);
                        }
                        if (!util_1.isNil(elem)) {
                            array.push(elem);
                        }
                    }
                    result = array;
                }
                else {
                    result = redact(current, expr, options);
                }
                if (util_1.isNil(result)) {
                    delete newObj[key]; // pruned result
                }
                else {
                    newObj[key] = result;
                }
            }
        }
        return newObj;
    },
};
/**
 * Computes the value of the expression on the object for the given operator
 *
 * @param obj the current object from the collection
 * @param expr the expression for the given field
 * @param operator the operator to resolve the field with
 * @param options {Object} extra options
 * @returns {*}
 */
function computeValue(obj, expr, operator, options) {
    // ensure valid options exist on first invocation
    options = options || makeOptions();
    if (util_1.isOperator(operator)) {
        // if the field of the object is a valid operator
        var call = getOperator(OperatorType.EXPRESSION, operator);
        if (call)
            return call(obj, expr, options);
        // we also handle $group accumulator operators
        call = getOperator(OperatorType.ACCUMULATOR, operator);
        if (call) {
            // if object is not an array, first try to compute using the expression
            if (!(obj instanceof Array)) {
                obj = computeValue(obj, expr, null, options);
                expr = null;
            }
            // validate that we have an array
            util_1.assert(obj instanceof Array, "'" + operator + "' target must be an array.");
            // we pass a null expression because all values have been resolved
            return call(obj, expr, options);
        }
        // operator was not found
        throw new Error("operator '" + operator + "' is not registered");
    }
    // if expr is a variable for an object field
    // field not used in this case
    if (util_1.isString(expr) && expr.length > 0 && expr[0] === "$") {
        // we return redact variables as literals
        if (util_1.has(redactVariables, expr)) {
            return expr;
        }
        // handle selectors with explicit prefix
        var arr = expr.split(".");
        if (util_1.has(systemVariables, arr[0])) {
            // set 'root' only the first time it is required to be used for all subsequent calls
            // if it already available on the options, it will be used
            obj = systemVariables[arr[0]](obj, null, util_1.into({ root: obj }, options));
            if (arr.length == 1)
                return obj;
            expr = expr.substr(arr[0].length); // '.' prefix will be sliced off below
        }
        return util_1.resolve(obj, expr.slice(1));
    }
    // check and return value if already in a resolved state
    if (expr instanceof Array) {
        return expr.map(function (item) {
            return computeValue(obj, item, null, options);
        });
    }
    else if (util_1.isObject(expr)) {
        var result = {};
        var _loop_4 = function (key, val) {
            result[key] = computeValue(obj, val, key, options);
            // must run ONLY one aggregate operator per expression
            // if so, return result of the computed value
            if ([OperatorType.EXPRESSION, OperatorType.ACCUMULATOR].some(function (c) {
                return util_1.has(OPERATORS[c], key);
            })) {
                // there should be only one operator
                util_1.assert(Object.keys(expr).length === 1, "Invalid aggregation expression '" + JSON.stringify(expr) + "'");
                return { value: result[key] };
            }
        };
        for (var _i = 0, _a = Object.entries(expr); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], val = _b[1];
            var state_1 = _loop_4(key, val);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return result;
    }
    else {
        return expr;
    }
}
exports.computeValue = computeValue;
/**
 * Redact an object
 * @param  {Object} obj The object to redact
 * @param  {*} expr The redact expression
 * @param  {*} options  Options for value
 * @return {*} returns the result of the redacted object
 */
function redact(obj, expr, options) {
    var result = computeValue(obj, expr, null, options);
    return util_1.has(redactVariables, result)
        ? redactVariables[result](obj, expr, util_1.into({ root: obj }, options))
        : result;
}
exports.redact = redact;
