"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stddev = void 0;
/**
 * Compute the standard deviation of the data set
 * @param {Array} array of numbers
 * @param {Boolean} if true calculates a sample standard deviation, otherwise calculates a population stddev
 * @return {Number}
 */
function stddev(data, sampled) {
    var sum = data.reduce(function (acc, n) { return acc + n; }, 0);
    var N = data.length || 1;
    var correction = (sampled && 1) || 0;
    var avg = sum / N;
    return Math.sqrt(data.reduce(function (acc, n) { return acc + Math.pow(n - avg, 2); }, 0) /
        (N - correction));
}
exports.stddev = stddev;
