"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Query = void 0;
var core_1 = require("./core");
var cursor_1 = require("./cursor");
var util_1 = require("./util");
/**
 * An object used to filter input documents
 *
 * @param {Object} criteria The criteria for constructing predicates
 * @param {Options} options Options for use by operators
 * @constructor
 */
var Query = /** @class */ (function () {
    function Query(criteria, options) {
        this.__criteria = criteria;
        this.__options = core_1.makeOptions(options);
        this.__compiled = [];
        this._compile();
    }
    Query.prototype._compile = function () {
        util_1.assert(util_1.isObject(this.__criteria), "query criteria must be an object");
        var whereOperator;
        for (var _i = 0, _a = Object.entries(this.__criteria); _i < _a.length; _i++) {
            var _b = _a[_i], field = _b[0], expr = _b[1];
            if ("$where" === field) {
                whereOperator = { field: field, expr: expr };
            }
            else if ("$expr" === field) {
                this._processOperator(field, field, expr);
            }
            else if (util_1.inArray(["$and", "$or", "$nor"], field)) {
                this._processOperator(field, field, expr);
            }
            else {
                // normalize expression
                util_1.assert(!util_1.isOperator(field), "unknown top level operator: " + field);
                for (var _c = 0, _d = Object.entries(util_1.normalize(expr)); _c < _d.length; _c++) {
                    var _e = _d[_c], operator = _e[0], val = _e[1];
                    this._processOperator(field, operator, val);
                }
            }
            if (util_1.isObject(whereOperator)) {
                this._processOperator(whereOperator.field, whereOperator.field, whereOperator.expr);
            }
        }
    };
    Query.prototype._processOperator = function (field, operator, value) {
        var call = core_1.getOperator(core_1.OperatorType.QUERY, operator);
        util_1.assert(!!call, "unknown operator " + operator);
        var fn = call(field, value, this.__options);
        this.__compiled.push(fn);
    };
    /**
     * Checks if the object passes the query criteria. Returns true if so, false otherwise.
     *
     * @param obj The object to test
     * @returns {boolean} True or false
     */
    Query.prototype.test = function (obj) {
        for (var i = 0, len = this.__compiled.length; i < len; i++) {
            if (!this.__compiled[i](obj)) {
                return false;
            }
        }
        return true;
    };
    /**
     * Returns a cursor to select matching documents from the input source.
     *
     * @param source A source providing a sequence of documents
     * @param projection An optional projection criteria
     * @returns {Cursor} A Cursor for iterating over the results
     */
    Query.prototype.find = function (collection, projection) {
        var _this = this;
        return new cursor_1.Cursor(collection, function (x) { return _this.test(x); }, projection || {}, this.__options);
    };
    /**
     * Remove matched documents from the collection returning the remainder
     *
     * @param collection An array of documents
     * @returns {Array} A new array with matching elements removed
     */
    Query.prototype.remove = function (collection) {
        var _this = this;
        return collection.reduce(function (acc, obj) {
            if (!_this.test(obj))
                acc.push(obj);
            return acc;
        }, []);
    };
    return Query;
}());
exports.Query = Query;
