"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var personNameField = {
  component: 'TextFieldHiddenSchema',
  maxLength: 40
};
var _default = personNameField;
exports.default = _default;