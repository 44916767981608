import AltDateWidget from "./AltDateWidget";
import AltDateTimeWidget from "./AltDateTimeWidget";
import BaseInput from "./BaseInput";
import CheckboxWidget from "./CheckboxWidget";
import CheckboxesWidget from "./CheckboxesWidget";
import ColorWidget from "./ColorWidget";
import DateWidget from "./DateWidget";
import DateTimeWidget from "./DateTimeWidget";
import EmailWidget from "./EmailWidget";
import FileWidget from "./FileWidget";
import HiddenWidget from "./HiddenWidget";
import PasswordWidget from "./PasswordWidget";
import RadioWidget from "./RadioWidget";
import RangeWidget from "./RangeWidget";
import SelectWidget from "./SelectWidget";
import TextareaWidget from "./TextareaWidget";
import TextWidget from "./TextWidget";
import URLWidget from "./URLWidget";
import UpDownWidget from "./UpDownWidget";
export default {
  BaseInput: BaseInput,
  PasswordWidget: PasswordWidget,
  RadioWidget: RadioWidget,
  UpDownWidget: UpDownWidget,
  RangeWidget: RangeWidget,
  SelectWidget: SelectWidget,
  TextWidget: TextWidget,
  DateWidget: DateWidget,
  DateTimeWidget: DateTimeWidget,
  AltDateWidget: AltDateWidget,
  AltDateTimeWidget: AltDateTimeWidget,
  EmailWidget: EmailWidget,
  URLWidget: URLWidget,
  TextareaWidget: TextareaWidget,
  HiddenWidget: HiddenWidget,
  ColorWidget: ColorWidget,
  FileWidget: FileWidget,
  CheckboxWidget: CheckboxWidget,
  CheckboxesWidget: CheckboxesWidget
};