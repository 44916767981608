"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Loads all Query and Projection operators
 */
// helpers
var core_1 = require("../core");
var booleanOperators = require("../operators/expression/boolean");
var comparisonOperators = require("../operators/expression/comparison");
var pipeline_1 = require("../operators/pipeline");
var projectionOperators = require("../operators/projection");
var queryOperators = require("../operators/query");
var util_1 = require("../util");
/**
 * Enable basic operators. This includes only query and projection operators
 */
function enableBasicOperators() {
    core_1.useOperators(core_1.OperatorType.EXPRESSION, util_1.into({}, booleanOperators, comparisonOperators));
    core_1.useOperators(core_1.OperatorType.PIPELINE, { $project: pipeline_1.$project, $skip: pipeline_1.$skip, $limit: pipeline_1.$limit, $sort: pipeline_1.$sort });
    core_1.useOperators(core_1.OperatorType.PROJECTION, projectionOperators);
    core_1.useOperators(core_1.OperatorType.QUERY, queryOperators);
}
enableBasicOperators();
