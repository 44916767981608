"use strict";
// Array Expression Operators: https://docs.mongodb.com/manual/reference/operator/aggregation/#array-expression-operators
Object.defineProperty(exports, "__esModule", { value: true });
exports.$filter = void 0;
var core_1 = require("../../../core");
var util_1 = require("../../../util");
/**
 * Selects a subset of the array to return an array with only the elements that match the filter condition.
 *
 * @param  {Object} obj  [description]
 * @param  {*} expr [description]
 * @return {*}      [description]
 */
function $filter(obj, expr, options) {
    var input = core_1.computeValue(obj, expr.input, null, options);
    util_1.assert(util_1.isArray(input), "$filter 'input' expression must resolve to an array");
    return input.filter(function (o) {
        // inject variable
        var tempObj = {};
        tempObj["$" + expr.as] = o;
        return core_1.computeValue(tempObj, expr.cond, null, options) === true;
    });
}
exports.$filter = $filter;
