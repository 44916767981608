"use strict";
// Trignometry Expression Operators: https://docs.mongodb.com/manual/reference/operator/aggregation/#trigonometry-expression-operators
Object.defineProperty(exports, "__esModule", { value: true });
exports.$radiansToDegrees = exports.$degreesToRadians = exports.$atanh = exports.$acosh = exports.$asinh = exports.$atan2 = exports.$atan = exports.$acos = exports.$asin = exports.$tan = exports.$cos = exports.$sin = void 0;
var core_1 = require("../../core");
var util_1 = require("../../util");
/**
 * Returns an operator for a given trignometric function
 *
 * @param f The trignometric function
 */
function createTrignometryOperator(f, returnInfinity) {
    return function (obj, expr, options) {
        var n = core_1.computeValue(obj, expr, null, options);
        if (isNaN(n) || util_1.isNil(n))
            return n;
        if (n === Infinity || n === -Infinity) {
            if (returnInfinity)
                return n;
            throw new Error("cannot apply $" + f.name + " to -inf, value must in (-inf,inf)");
        }
        return f(n);
    };
}
/** Returns the sine of a value that is measured in radians. */
exports.$sin = createTrignometryOperator(Math.sin);
/** Returns the cosine of a value that is measured in radians. */
exports.$cos = createTrignometryOperator(Math.cos);
/** Returns the tangent of a value that is measured in radians. */
exports.$tan = createTrignometryOperator(Math.tan);
/** Returns the inverse sin (arc sine) of a value in radians. */
exports.$asin = createTrignometryOperator(Math.asin);
/** Returns the inverse cosine (arc cosine) of a value in radians. */
exports.$acos = createTrignometryOperator(Math.acos);
/** Returns the inverse tangent (arc tangent) of a value in radians. */
exports.$atan = createTrignometryOperator(Math.atan);
/**
 * Returns the inverse tangent (arc tangent) of y / x in radians, where y and x are the first and second values passed to the expression respectively. */
function $atan2(obj, expr, options) {
    var _a = core_1.computeValue(obj, expr, null, options), y = _a[0], x = _a[1];
    if (isNaN(y) || util_1.isNil(y))
        return y;
    if (isNaN(x) || util_1.isNil(x))
        return x;
    return Math.atan2(y, x);
}
exports.$atan2 = $atan2;
/** Returns the inverse hyperbolic sine (hyperbolic arc sine) of a value in radians. */
exports.$asinh = createTrignometryOperator(Math.asinh);
/** Returns the inverse hyperbolic cosine (hyperbolic arc cosine) of a value in radians. */
exports.$acosh = createTrignometryOperator(Math.acosh);
/** Returns the inverse hyperbolic tangent (hyperbolic arc tangent) of a value in radians. */
exports.$atanh = createTrignometryOperator(Math.atanh);
var RADIANS_FACTOR = Math.PI / 180;
/** Converts a value from degrees to radians. */
exports.$degreesToRadians = createTrignometryOperator(function (n) { return n * RADIANS_FACTOR; }, true /*returnInfinity*/);
var DEGREES_FACTOR = 180 / Math.PI;
/** Converts a value from radians to degrees. */
exports.$radiansToDegrees = createTrignometryOperator(function (n) { return n * DEGREES_FACTOR; }, true /*returnInfinity*/);
