"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "enhancer", {
  enumerable: true,
  get: function get() {
    return _enhancer.default;
  }
});
Object.defineProperty(exports, "reduxFirestore", {
  enumerable: true,
  get: function get() {
    return _enhancer.default;
  }
});
Object.defineProperty(exports, "getFirestore", {
  enumerable: true,
  get: function get() {
    return _enhancer.getFirestore;
  }
});
Object.defineProperty(exports, "reducer", {
  enumerable: true,
  get: function get() {
    return _reducer.default;
  }
});
Object.defineProperty(exports, "firestoreReducer", {
  enumerable: true,
  get: function get() {
    return _reducer.default;
  }
});
Object.defineProperty(exports, "actions", {
  enumerable: true,
  get: function get() {
    return _actions.firestoreActions;
  }
});
Object.defineProperty(exports, "createFirestoreInstance", {
  enumerable: true,
  get: function get() {
    return _createFirestoreInstance.default;
  }
});
Object.defineProperty(exports, "constants", {
  enumerable: true,
  get: function get() {
    return _constants.default;
  }
});
Object.defineProperty(exports, "actionTypes", {
  enumerable: true,
  get: function get() {
    return _constants.actionTypes;
  }
});
Object.defineProperty(exports, "middleware", {
  enumerable: true,
  get: function get() {
    return _middleware.default;
  }
});
Object.defineProperty(exports, "CALL_FIRESTORE", {
  enumerable: true,
  get: function get() {
    return _middleware.CALL_FIRESTORE;
  }
});
Object.defineProperty(exports, "getSnapshotByObject", {
  enumerable: true,
  get: function get() {
    return _query.getSnapshotByObject;
  }
});
exports.default = exports.version = void 0;

var _enhancer = _interopRequireWildcard(require("./enhancer"));

var _reducer = _interopRequireDefault(require("./reducer"));

var _actions = require("./actions");

var _createFirestoreInstance = _interopRequireDefault(require("./createFirestoreInstance"));

var _constants = _interopRequireWildcard(require("./constants"));

var _middleware = _interopRequireWildcard(require("./middleware"));

var _query = require("./utils/query");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var version = "0.14.0";
exports.version = version;
var _default = {
  version: version,
  reducer: _reducer.default,
  firestoreReducer: _reducer.default,
  enhancer: _enhancer.default,
  reduxFirestore: _enhancer.default,
  createFirestoreInstance: _createFirestoreInstance.default,
  actions: _actions.firestoreActions,
  getFirestore: _enhancer.getFirestore,
  getSnapshotByObject: _query.getSnapshotByObject,
  constants: _constants.default,
  actionTypes: _constants.actionTypes,
  middleware: _middleware.default,
  CALL_FIRESTORE: _middleware.CALL_FIRESTORE
};
exports.default = _default;