"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var personFullNameField = {
  component: 'CompositeField',
  schema: {
    component: 'Form',
    fields: [{
      // Hide from docs as label doesn't apply
      name: 'label',
      docLevel: null
    }]
  },
  fields: [{
    name: 'firstName',
    label: 'First Name',
    component: 'PersonNameField',
    block: false
  }, {
    name: 'lastName',
    label: 'Last Name',
    component: 'PersonNameField'
  }]
};
var _default = personFullNameField;
exports.default = _default;