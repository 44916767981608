"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var cityField = {
  component: 'TextFieldHiddenSchema',
  maxLength: 40
};
var _default = cityField;
exports.default = _default;