"use strict";
// $elemMatch operator. https://docs.mongodb.com/manual/reference/operator/projection/elemMatch/#proj._S_elemMatch
Object.defineProperty(exports, "__esModule", { value: true });
exports.$elemMatch = void 0;
var query_1 = require("../../query");
var util_1 = require("../../util");
/**
 * Projects only the first element from an array that matches the specified $elemMatch condition.
 *
 * @param obj
 * @param field
 * @param expr
 * @returns {*}
 */
function $elemMatch(obj, expr, field, options) {
    var arr = util_1.resolve(obj, field);
    var query = new query_1.Query(expr, options);
    util_1.assert(arr instanceof Array, "$elemMatch: argument must resolve to array");
    for (var i = 0; i < arr.length; i++) {
        if (query.test(arr[i]))
            return [arr[i]];
    }
    return undefined;
}
exports.$elemMatch = $elemMatch;
