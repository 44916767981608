"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _action = _interopRequireDefault(require("./actions/action"));

var _addressField = _interopRequireDefault(require("./fields/address-field"));

var _addressForm = _interopRequireDefault(require("./fields/address-form"));

var _authenticatedContactUs = _interopRequireDefault(require("./aggregate/authenticated-contact-us"));

var _app = _interopRequireDefault(require("./app"));

var _booleanField = _interopRequireDefault(require("./fields/boolean-field"));

var _buttonField = _interopRequireDefault(require("./fields/button-field"));

var _card = _interopRequireDefault(require("./card"));

var _chainedSelectField = _interopRequireDefault(require("./fields/chained-select-field"));

var _chainedSelectListField = _interopRequireDefault(require("./fields/chained-select-list-field"));

var _chainedSelectOptionsField = _interopRequireDefault(require("./fields/chained-select-options-field"));

var _cityField = _interopRequireDefault(require("./fields/city-field"));

var _component = _interopRequireDefault(require("./component/component"));

var _collectionField = _interopRequireDefault(require("./fields/collection-field"));

var _componentField = _interopRequireDefault(require("./fields/component-field"));

var _componentForm = _interopRequireDefault(require("./form/component-form"));

var _compositeField = _interopRequireDefault(require("./fields/composite-field"));

var _consoleLog = _interopRequireDefault(require("./actions/console-log"));

var _contactUs = _interopRequireDefault(require("./aggregate/contact-us"));

var _countryField = _interopRequireDefault(require("./fields/country-field"));

var _dateField = _interopRequireDefault(require("./fields/date-field"));

var _email = _interopRequireDefault(require("./actions/email"));

var _emailField = _interopRequireDefault(require("./fields/email-field"));

var _emit = _interopRequireDefault(require("./actions/emit"));

var _extendedField = _interopRequireDefault(require("./fields/extended-field"));

var _factory = _interopRequireDefault(require("./component/factory"));

var _field = _interopRequireDefault(require("./fields/field"));

var _firebaseStore = _interopRequireDefault(require("./stores/firebase-store"));

var _form = _interopRequireDefault(require("./form"));

var _formAccess = _interopRequireDefault(require("./form/form-access"));

var _formField = _interopRequireDefault(require("./fields/form-field"));

var _formValidator = _interopRequireDefault(require("./form/form-validator"));

var _fragment = _interopRequireDefault(require("./fragment"));

var _generateComponent = _interopRequireDefault(require("./actions/generate-component"));

var _getDoc = _interopRequireDefault(require("./actions/get-doc"));

var _getDocs = _interopRequireDefault(require("./actions/get-docs"));

var _grid = _interopRequireDefault(require("./grid"));

var _gridItem = _interopRequireDefault(require("./grid-item"));

var _idField = _interopRequireDefault(require("./fields/id-field"));

var _integerField = _interopRequireDefault(require("./fields/integer-field"));

var _iterator = _interopRequireDefault(require("./actions/iterator"));

var _javaScript = _interopRequireDefault(require("./actions/java-script"));

var _listField = _interopRequireDefault(require("./fields/list-field"));

var _localStorageStore = _interopRequireDefault(require("./stores/local-storage-store"));

var _login = _interopRequireDefault(require("./aggregate/login"));

var _logInToApp = _interopRequireDefault(require("./actions/log-in-to-app"));

var _logInToAppAndRedirect = _interopRequireDefault(require("./actions/log-in-to-app-and-redirect"));

var _logOutOfApp = _interopRequireDefault(require("./actions/log-out-of-app"));

var _memoryStore = _interopRequireDefault(require("./stores/memory-store"));

var _menu = _interopRequireDefault(require("./menu"));

var _moneyField = _interopRequireDefault(require("./fields/money-field"));

var _msonComponent = _interopRequireDefault(require("./component/mson-component"));

var _numberField = _interopRequireDefault(require("./fields/number-field"));

var _objectForm = _interopRequireDefault(require("./object-form"));

var _optionsField = _interopRequireDefault(require("./fields/options-field"));

var _passwordField = _interopRequireDefault(require("./fields/password-field"));

var _personFullNameField = _interopRequireDefault(require("./fields/person-full-name-field"));

var _personNameField = _interopRequireDefault(require("./fields/person-name-field"));

var _phoneField = _interopRequireDefault(require("./fields/phone-field"));

var _postalCodeField = _interopRequireDefault(require("./fields/postal-code-field"));

var _provinceField = _interopRequireDefault(require("./fields/province-field"));

var _reCaptchaField = _interopRequireDefault(require("./fields/re-captcha-field"));

var _reCaptchaVault = _interopRequireDefault(require("./vaults/re-captcha-vault"));

var _recordEditor = _interopRequireDefault(require("./aggregate/record-editor"));

var _recordList = _interopRequireDefault(require("./aggregate/record-list"));

var _recordStore = _interopRequireDefault(require("./stores/record-store"));

var _recoverPassword = _interopRequireDefault(require("./aggregate/recover-password"));

var _recoverPasswordEditor = _interopRequireDefault(require("./aggregate/recover-password-editor"));

var _redirect = _interopRequireDefault(require("./actions/redirect"));

var _redirectByRole = _interopRequireDefault(require("./actions/redirect-by-role"));

var _regExpField = _interopRequireDefault(require("./fields/reg-exp-field"));

var _requestPasswordReset = _interopRequireDefault(require("./actions/request-password-reset"));

var _resetPassword = _interopRequireDefault(require("./actions/reset-password"));

var _resetPasswordEditor = _interopRequireDefault(require("./aggregate/reset-password-editor"));

var _rolesField = _interopRequireDefault(require("./fields/roles-field"));

var _schemaValidatorForm = _interopRequireDefault(require("./form/schema-validator-form"));

var _selectField = _interopRequireDefault(require("./fields/select-field"));

var _selectListField = _interopRequireDefault(require("./fields/select-list-field"));

var _setFromDoc = _interopRequireDefault(require("./actions/set-from-doc"));

var _set = _interopRequireDefault(require("./actions/set"));

var _signupEditor = _interopRequireDefault(require("./aggregate/signup-editor"));

var _snackbar = _interopRequireDefault(require("./actions/snackbar"));

var _stateField = _interopRequireDefault(require("./fields/state-field"));

var _tabs = _interopRequireDefault(require("./tabs"));

var _text = _interopRequireDefault(require("./text"));

var _textField = _interopRequireDefault(require("./fields/text-field"));

var _textFieldHiddenSchema = _interopRequireDefault(require("./fields/text-field-hidden-schema"));

var _textListField = _interopRequireDefault(require("./fields/text-list-field"));

var _timeField = _interopRequireDefault(require("./fields/time-field"));

var _updatePasswordEditor = _interopRequireDefault(require("./aggregate/update-password-editor"));

var _updatePassword = _interopRequireDefault(require("./aggregate/update-password"));

var _upsertDoc = _interopRequireDefault(require("./actions/upsert-doc"));

var _urlField = _interopRequireDefault(require("./fields/url-field"));

var _user = _interopRequireDefault(require("./form/user"));

var _userList = _interopRequireDefault(require("./aggregate/user-list"));

var _uuidField = _interopRequireDefault(require("./fields/uuid-field"));

var _vault = _interopRequireDefault(require("./vaults/vault"));

var _whereField = _interopRequireDefault(require("./fields/where-field"));

var _wrappedComponent = _interopRequireDefault(require("./component/wrapped-component"));

// NOTE:
// - This file should only contain the component registrations
// - Names of user-defined fields should be in the form org.proj.ComponentName. They should not
//   appear in this file though!
var components = {
  AccessForm: _formAccess.default,
  Action: _action.default,
  AddressField: _addressField.default,
  AddressForm: _addressForm.default,
  AuthenticatedContactUs: _authenticatedContactUs.default,
  App: _app.default,
  BooleanField: _booleanField.default,
  ButtonField: _buttonField.default,
  Card: _card.default,
  ChainedSelectField: _chainedSelectField.default,
  ChainedSelectListField: _chainedSelectListField.default,
  ChainedSelectOptionsField: _chainedSelectOptionsField.default,
  CityField: _cityField.default,
  CollectionField: _collectionField.default,
  Component: _component.default,
  ComponentField: _componentField.default,
  ComponentForm: _componentForm.default,
  CompositeField: _compositeField.default,
  ConsoleLog: _consoleLog.default,
  ContactUs: _contactUs.default,
  CountryField: _countryField.default,
  DateField: _dateField.default,
  Email: _email.default,
  EmailField: _emailField.default,
  Emit: _emit.default,
  ExtendedField: _extendedField.default,
  Factory: _factory.default,
  Field: _field.default,
  FirebaseStore: _firebaseStore.default,
  Form: _form.default,
  FormField: _formField.default,
  Fragment: _fragment.default,
  GenerateComponent: _generateComponent.default,
  GetDoc: _getDoc.default,
  GetDocs: _getDocs.default,
  Grid: _grid.default,
  GridItem: _gridItem.default,
  IdField: _idField.default,
  IntegerField: _integerField.default,
  Iterator: _iterator.default,
  JavaScript: _javaScript.default,
  ListField: _listField.default,
  LocalStorageStore: _localStorageStore.default,
  Login: _login.default,
  LogInToApp: _logInToApp.default,
  LogInToAppAndRedirect: _logInToAppAndRedirect.default,
  LogOutOfApp: _logOutOfApp.default,
  MemoryStore: _memoryStore.default,
  Menu: _menu.default,
  MoneyField: _moneyField.default,
  MSONComponent: _msonComponent.default,
  NumberField: _numberField.default,
  ObjectForm: _objectForm.default,
  OptionsField: _optionsField.default,
  PasswordField: _passwordField.default,
  PersonFullNameField: _personFullNameField.default,
  PersonNameField: _personNameField.default,
  PhoneField: _phoneField.default,
  PostalCodeField: _postalCodeField.default,
  ProvinceField: _provinceField.default,
  ReCAPTCHAField: _reCaptchaField.default,
  ReCAPTCHAVault: _reCaptchaVault.default,
  RecordEditor: _recordEditor.default,
  RecordList: _recordList.default,
  RecordStore: _recordStore.default,
  RecoverPassword: _recoverPassword.default,
  RecoverPasswordEditor: _recoverPasswordEditor.default,
  Redirect: _redirect.default,
  RedirectByRole: _redirectByRole.default,
  RegExpField: _regExpField.default,
  RequestPasswordReset: _requestPasswordReset.default,
  ResetPassword: _resetPassword.default,
  ResetPasswordEditor: _resetPasswordEditor.default,
  RolesField: _rolesField.default,
  SchemaValidatorForm: _schemaValidatorForm.default,
  SelectField: _selectField.default,
  SelectListField: _selectListField.default,
  SetFromDoc: _setFromDoc.default,
  Set: _set.default,
  SignupEditor: _signupEditor.default,
  Snackbar: _snackbar.default,
  StateField: _stateField.default,
  Tabs: _tabs.default,
  Text: _text.default,
  TextField: _textField.default,
  TextFieldHiddenSchema: _textFieldHiddenSchema.default,
  TextListField: _textListField.default,
  TimeField: _timeField.default,
  ValidatorForm: _formValidator.default,
  UpdatePasswordEditor: _updatePasswordEditor.default,
  UpdatePassword: _updatePassword.default,
  UpsertDoc: _upsertDoc.default,
  URLField: _urlField.default,
  User: _user.default,
  UserList: _userList.default,
  UUIDField: _uuidField.default,
  Vault: _vault.default,
  WhereField: _whereField.default,
  WrappedComponent: _wrappedComponent.default
};
var _default = components;
exports.default = _default;