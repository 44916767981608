"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var Roles = function Roles() {
  (0, _classCallCheck2.default)(this, Roles);
};

Roles.OWNER = 'owner';
Roles.ID_OWNER = 1;
Roles.RESERVED = {
  [Roles.OWNER]: Roles.ID_OWNER
};

Roles.isReserved = function (roleName) {
  return Roles.RESERVED[roleName] ? true : false;
};

Roles.getId = function (roleName) {
  return Roles.RESERVED[roleName];
};

var _default = Roles;
exports.default = _default;