"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _buttonField = _interopRequireDefault(require("./fields/button-field"));

var _booleanField = _interopRequireDefault(require("./fields/boolean-field"));

var _card = _interopRequireDefault(require("./card"));

var _componentField = _interopRequireDefault(require("./fields/component-field"));

var _compositeField = _interopRequireDefault(require("./fields/composite-field"));

var _dateField = _interopRequireDefault(require("./fields/date-field"));

var _field = _interopRequireDefault(require("./fields/field"));

var _form = _interopRequireDefault(require("./form"));

var _formField = _interopRequireDefault(require("./fields/form-field"));

var _fragment = _interopRequireDefault(require("./fragment"));

var _grid = _interopRequireDefault(require("./grid"));

var _gridItem = _interopRequireDefault(require("./grid-item"));

var _collectionField = _interopRequireDefault(require("./fields/collection-field"));

var _listField = _interopRequireDefault(require("./fields/list-field"));

var _reCaptchaField = _interopRequireDefault(require("./fields/re-captcha-field"));

var _selectField = _interopRequireDefault(require("./fields/select-field"));

var _tabs = _interopRequireDefault(require("./tabs"));

var _timeField = _interopRequireDefault(require("./fields/time-field"));

var _text = _interopRequireDefault(require("./text"));

var _textField = _interopRequireDefault(require("./fields/text-field"));

var _urlField = _interopRequireDefault(require("./fields/url-field"));

// NOTE: this file should only contain the component registrations
var components = {
  ButtonField: _buttonField.default,
  BooleanField: _booleanField.default,
  Card: _card.default,
  ChainedSelectField: _compositeField.default,
  ChainedSelectListField: _listField.default,
  ComponentField: _componentField.default,
  CompositeField: _compositeField.default,
  DateField: _dateField.default,
  Field: _field.default,
  Form: _form.default,
  FormField: _formField.default,
  Fragment: _fragment.default,
  Grid: _grid.default,
  GridItem: _gridItem.default,
  CollectionField: _collectionField.default,
  IdField: _textField.default,
  IntegerField: _textField.default,
  ListField: _listField.default,
  MoneyField: _textField.default,
  NumberField: _textField.default,
  PhoneField: _textField.default,
  ReCAPTCHAField: _reCaptchaField.default,
  SelectField: _selectField.default,
  SelectListField: _listField.default,
  Tabs: _tabs.default,
  TimeField: _timeField.default,
  Text: _text.default,
  TextField: _textField.default,
  TextListField: _listField.default,
  URLField: _urlField.default,
  User: _form.default
};
var _default = components;
exports.default = _default;