"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var recordList = {
  name: 'RecordList',
  component: 'Form',
  schema: {
    component: 'Form',
    fields: [{
      name: 'baseFormFactory',
      component: 'Field',
      required: true
    }, {
      name: 'label',
      component: 'TextField',
      required: true
    }, {
      name: 'store',
      component: 'Field'
    }]
  },
  fields: [{
    name: 'collection',
    label: '{{label}}',
    component: 'CollectionField',
    hideLabel: true,
    formFactory: {
      component: 'Factory',
      product: '{{baseFormFactory}}'
    },
    store: '{{store}}'
  }]
};
var _default = recordList;
exports.default = _default;