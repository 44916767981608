"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// all system operators
// helpers
var core_1 = require("../core");
var accumulatorOperators = require("../operators/accumulator");
var expressionOperators = require("../operators/expression");
var pipelineOperators = require("../operators/pipeline");
var projectionOperators = require("../operators/projection");
var queryOperators = require("../operators/query");
/**
 * Enable all supported MongoDB operators
 */
function enableSystemOperators() {
    core_1.useOperators(core_1.OperatorType.ACCUMULATOR, accumulatorOperators);
    core_1.useOperators(core_1.OperatorType.EXPRESSION, expressionOperators);
    core_1.useOperators(core_1.OperatorType.PIPELINE, pipelineOperators);
    core_1.useOperators(core_1.OperatorType.PROJECTION, projectionOperators);
    core_1.useOperators(core_1.OperatorType.QUERY, queryOperators);
}
enableSystemOperators();
