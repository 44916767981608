"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cursor = void 0;
var aggregator_1 = require("./aggregator");
var lazy_1 = require("./lazy");
var util_1 = require("./util");
/**
 * Cursor to iterate and perform filtering on matched objects.
 * This object must not be used directly. A cursor may be obtaine from calling `find()` on an instance of `Query`.
 *
 * @param collection The input source of the collection
 * @param predicate A predicate function to test documents
 * @param projection A projection criteria
 * @param options Options
 * @constructor
 */
var Cursor = /** @class */ (function () {
    function Cursor(source, predicate, projection, options) {
        this.__predicateFn = predicate;
        this.__source = source;
        this.__projection = projection;
        this.__operators = [];
        this.__result = null;
        this.__stack = [];
        this.__options = options;
    }
    Cursor.prototype._fetch = function () {
        if (this.__result)
            return this.__result;
        // add projection operator
        if (util_1.isObject(this.__projection))
            this.__operators.push({ $project: this.__projection });
        // filter collection
        this.__result = lazy_1.Lazy(this.__source).filter(this.__predicateFn);
        if (this.__operators.length > 0) {
            this.__result = new aggregator_1.Aggregator(this.__operators, this.__options).stream(this.__result);
        }
        return this.__result;
    };
    /**
     * Return remaining objects in the cursor as an array. This method exhausts the cursor
     * @returns {Array}
     */
    Cursor.prototype.all = function () {
        return this._fetch().value();
    };
    /**
     * Returns the number of objects return in the cursor. This method exhausts the cursor
     * @returns {Number}
     */
    Cursor.prototype.count = function () {
        return this.all().length;
    };
    /**
     * Returns a cursor that begins returning results only after passing or skipping a number of documents.
     * @param {Number} n the number of results to skip.
     * @return {Cursor} Returns the cursor, so you can chain this call.
     */
    Cursor.prototype.skip = function (n) {
        this.__operators.push({ $skip: n });
        return this;
    };
    /**
     * Constrains the size of a cursor's result set.
     * @param {Number} n the number of results to limit to.
     * @return {Cursor} Returns the cursor, so you can chain this call.
     */
    Cursor.prototype.limit = function (n) {
        this.__operators.push({ $limit: n });
        return this;
    };
    /**
     * Returns results ordered according to a sort specification.
     * @param {Object} modifier an object of key and values specifying the sort order. 1 for ascending and -1 for descending
     * @return {Cursor} Returns the cursor, so you can chain this call.
     */
    Cursor.prototype.sort = function (modifier) {
        this.__operators.push({ $sort: modifier });
        return this;
    };
    /**
     * Specifies the collation for the cursor returned by the `mingo.Query.find`
     * @param {*} spec
     */
    Cursor.prototype.collation = function (spec) {
        util_1.into(this.__options, { collation: spec });
        return this;
    };
    /**
     * Returns the next document in a cursor.
     * @returns {Object | Boolean}
     */
    Cursor.prototype.next = function () {
        if (!this.__stack)
            return; // done
        if (this.__stack.length > 0)
            return this.__stack.pop(); // yield value obtains in hasNext()
        var o = this._fetch().next();
        if (!o.done)
            return o.value;
        this.__stack = null;
        return;
    };
    /**
     * Returns true if the cursor has documents and can be iterated.
     * @returns {boolean}
     */
    Cursor.prototype.hasNext = function () {
        if (!this.__stack)
            return false; // done
        if (this.__stack.length > 0)
            return true; // there is a value on stack
        var o = this._fetch().next();
        if (!o.done) {
            this.__stack.push(o.value);
        }
        else {
            this.__stack = null;
        }
        return !!this.__stack;
    };
    /**
     * Applies a function to each document in a cursor and collects the return values in an array.
     * @param callback
     * @returns {Array}
     */
    Cursor.prototype.map = function (callback) {
        return this._fetch().map(callback).value();
    };
    /**
     * Applies a JavaScript function for every document in a cursor.
     * @param callback
     */
    Cursor.prototype.forEach = function (callback) {
        this._fetch().each(callback);
    };
    return Cursor;
}());
exports.Cursor = Cursor;
if (typeof Symbol === "function") {
    /**
     * Applies an [ES2015 Iteration protocol][] compatible implementation
     * [ES2015 Iteration protocol]: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Iteration_protocols
     * @returns {Object}
     */
    Cursor.prototype[Symbol.iterator] = function () {
        return this._fetch(); /* eslint-disable-line */
    };
}
