"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var addressField = {
  component: 'FormField',
  form: {
    component: 'AddressForm'
  },
  elevate: true
};
var _default = addressField;
exports.default = _default;