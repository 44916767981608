"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var emailField = {
  component: 'TextFieldHiddenSchema',
  maxLength: '50',
  validators: [{
    where: {
      value: {
        $not: {
          $regex: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$'
        }
      }
    },
    error: 'invalid email'
  }]
};
var _default = emailField;
exports.default = _default;