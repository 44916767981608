"use strict";
/**
 * Type Expression Operators: https://docs.mongodb.com/manual/reference/operator/aggregation/#type-expression-operators
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.$isNumber = void 0;
var core_1 = require("../../../core");
var util_1 = require("../../../util");
/**
 * Checks if the specified expression resolves to a numeric value
 *
 * @param obj
 * @param expr
 */
function $isNumber(obj, expr, options) {
    var n = core_1.computeValue(obj, expr, null, options);
    return util_1.isNumber(n);
}
exports.$isNumber = $isNumber;
